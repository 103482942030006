<template>
  <div class="d-flex flex-column centered w-100 mw-100">
    <div class="rj-logo">
      <img src="./assets/ruejai-app-logo-optimize.png" class="w-100" />
    </div>
    <div>
      <button
        id="openRueJaiApp"
        class="btn btn-primary rj-button"
        @click="handleOpenApp"
      >
        Open App
      </button>
    </div>
    <div>
      <button
        id="downloadRuejaiApp"
        class="btn btn-primary rj-button"
        @click="handleOpenStore"
      >
        Download App
      </button>
    </div>
    <div class="text-center mt-3" style="font-size: 12px; color: #ffffff">
      <p>v1.0.0-RJ</p>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.isMobile) {
      this.handleOpenApp();
    }
  },
  computed: {
    isMobile() {
      return this.isAndroid || this.isIOS;
    },
    isAndroid() {
      return navigator.userAgent.includes("Android");
    },
    isIOS() {
      return (
        navigator.userAgent.includes("iPhone") ||
        navigator.userAgent.includes("iPad")
      );
    },
    openStoreURL() {
      if (this.isAndroid) {
        return "https://play.google.com/store/apps/details?id=com.fireoneone.baanruejai";
      } else if (this.isIOS) {
        return "https://apps.apple.com/us/app/baan-ruejai/id1375938415";
      } else {
        return "https://www.scasset.com/baanruejai/index.html";
      }
    },
    openAppURL() {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const queryString = queryParams.toString();

        if (this.isAndroid) {
          return `intent://sc.ruejai.app/content?${queryString}#Intent;scheme=ruejai-app;package=com.fireoneone.baanruejai;end`;
        } else if (this.isIOS) {
          return `com.fireoneone.BaanRueJai:ruejai-app.web.app/content?${queryString}`;
        } else {
          return "https://www.scasset.com/baanruejai/index.html";
        }
      } catch {
        return "https://www.scasset.com/baanruejai/index.html";
      }
    },
  },
  methods: {
    handleOpenApp() {
      if (this.isAndroid) {
        window.open(this.openAppURL, "_self");
      } else {
        window.location = this.openAppURL;
      }
    },
    handleOpenStore() {
      if (this.isAndroid) {
        window.open(this.openStoreURL, "_self");
      } else {
        window.location = this.openStoreURL;
      }
    },
  },
};
</script>

<style scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rj-logo {
  width: 60%;
  max-width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

.rj-button {
  width: 60%;
  max-width: 60%;
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
  font-weight: bold;
  width: 100%;
  background-color: #e27024;
  border-color: #e27024;
}
</style>
